import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/public/logo-short.svg");
;
import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/public/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/apps/dashboard/src/app/api/auth/[...nextauth]/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/apps/dashboard/src/app/shared/drawer-views/container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/apps/dashboard/src/app/shared/modal-views/container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/var/www/apps/dashboard/src/app/shared/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/src/lib/query-client-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/www/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/packages/isomorphic-core/src/components/next-progress.tsx");
